import { StaticImage } from 'gatsby-plugin-image'
import Carousel from 'nuka-carousel'
import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import Layout from '../components/Layouts/default'

const partners = [
  {
    name: 'GoJek',
    link: 'https://www.gojek.com/vn/gofood/',
    logo: (
      <StaticImage
        src="../assets/images/partners/gojek-logo.png"
        alt="GoJek"
        width={200}
      ></StaticImage>
    ),
  },
  {
    name: 'ShopeeFood',
    link: 'https://shopeefood.vn/thuong-hieu/savor-banh-mi-tra-sua',
    logo: (
      <StaticImage
        src="../assets/images/partners/shopee-food.png"
        alt="ShoppeeFood"
        width={200}
      ></StaticImage>
    ),
  },
  {
    name: 'BAEMIN',
    link: 'https://baemin.vn/',
    logo: (
      <StaticImage
        src="../assets/images/partners/baemin-logo.png"
        alt="BAEMIN"
        width={200}
      ></StaticImage>
    ),
  },
  {
    name: 'GrabFood',
    link: 'https://food.grab.com/vn/en/restaurants?lng=en&search=savor',
    logo: (
      <StaticImage
        src="../assets/images/partners/grabfood.png"
        alt="GrabFood"
        width={200}
      ></StaticImage>
    ),
  },
  {
    name: 'befood',
    link: 'https://be.com.vn/#download-app',
    logo: (
      <StaticImage
        src="../assets/images/partners/be.jpg"
        alt="befood"
        width={200}
      ></StaticImage>
    ),
  },
]

export default function ShipHangPage() {
  const settings = {
    className: 'ship-slider',
    autoplay: true,
    autoplayInterval: 3000,
    slidesToShow: 3,
    wrapAround: true,
    adaptiveHeight: false,
    defaultControlsConfig: {
      nextButtonText: '>',
      prevButtonText: '<',
    },
  }
  return (
    <Layout>
      <Container text style={{ marginTop: 20 }}>
        <Header as="h2">Đối tác ship hàng</Header>

        <Carousel {...settings}>
          {partners.map((partner, index) => (
            <a
              href={partner.link}
              target="_blank"
              rel="noreferrer noopener"
              key={partner.name}
            >
              {partner.logo}
            </a>
          ))}
        </Carousel>
      </Container>
    </Layout>
  )
}
